import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: 'M PLUS Rounded 1c', sans-serif;
font-family: 'Varela Round', sans-serif;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    
    background-color: #ffffff;
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%232d008f'/%3E%3Cstop offset='1' stop-color='%234700e3'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='0' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E"); */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top left;
    height: 100vh;
    /* text-rendering: optimizeLegibility; */
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(163, 163, 163);
  }

  

  div {
  }

  small {
    display: block;
  }
  p{
    margin:0;
  }
  a {
    color: ${({ theme }) => theme.primaryHover};
    text-decoration: none;
  }
  .float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:80px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
    transition: all .2s ease-in-out;
}

.float:hover{
    color: #f8f9fa;
    /* background-color: #5d00ff; */
    transform: scale(1.3);
}

.my-float{
	margin-top:16px;
}
  .float-left{
	position:fixed;
	width:60px;
	height:60px;
	bottom:80px;
	left:40px;
	background-color:#5d00ff;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
    transition: all .2s ease-in-out;
}
@media (max-width: ${({ theme }) => theme.mobile}) {
  .float-left{
    display:none;
  }
}

.float-left:hover{
    color: #f8f9fa;
    /* background-color: #5d00ff; */
    transform: scale(1.3);
}

.my-float-left{
	margin-top:16px;
}
`
