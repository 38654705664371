import styled from 'styled-components'

export const HeaderContainer = styled.div`
  height: 100vmin;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding: 20px;
  background-color: #5000ff;
  @media (max-width: ${({ theme }) => theme.mobile}) {
      height: 100vmax;
      align-items: left;
      img{
        width:350px;
      }
    }
`

export const HeaderInfoContainer = styled.div`
  z-index: 2;
  position: relative;
`