import React from 'react';

import { PortfolioContainer, PortfolioImage, BrandMark  } from './styles';
import ImgPortfolio from '../../assets/static/portfolio.png'
import { Container } from 'react-bootstrap';

export const DownloadPortfolio = () => {
  return (
    <div id='download'>
      <Container className='mt-5 mb-5'>
      <PortfolioContainer>
        <h1 className='text-center'>Siempre que veas...</h1>
            <BrandMark>
            <div className="d-flex justify-content-center">
                <p>Diseñado y Desarrollado con <i className="fas fa-heart"/> y <i className="fas fa-mug-hot"/> por PooPaye SAC</p> 
            </div>
            </BrandMark>
            <h2 >Es un trabajo nuestro 😉</h2>
            <br/>
        <a href="https://docdro.id/h8r9LXw" target='_blank' rel="noreferrer">
              <PortfolioImage  src={ImgPortfolio} width='400px' alt='portfolio'/>
            </a>
            <h5 className='mt-5 mb-5'>Presiona en la imagen para ver más de nuestros trabajos</h5>  
        

      </PortfolioContainer>
      </Container>
    </div>
  );
};