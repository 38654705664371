import styled from 'styled-components'

export const BrandMark = styled.div`
  background-color: #5000ff;
/* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(360,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23ff6e00'/%3E%3Cstop offset='1' stop-color='%235000ff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='39' height='39' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='19.5' cy='19.5' r='19.5'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.04'/%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover; */
  padding: 50px 0px;
  margin: 20px ;
  text-align: center;
  padding: 40px;
  color: white;
  border-radius: 25px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 20px 0px;
    text-align: center;
    padding: 10px;
    font-size: 17px;
    }
`
export const PortfolioContainer = styled.div`
  
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 100%;
    }
  
`
export const PortfolioImage = styled.img`
  width:350px;
  border-radius: 10px;
  transition: all .2s ease-in-out; 
  &:hover{
    transform: scale(1.1);
  }  
`