import React, { useRef, useState } from 'react'
import { Burger, Menu } from '../../components'
import { useOnClickOutside } from '../../hooks/hooks.hamburguer';
import FocusLock from 'react-focus-lock';


export const NavigationBar = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  useOnClickOutside(node, () => setOpen(false));
  return (
    <div ref={node} >
      <FocusLock disabled={!open}>
        <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
        <Menu open={open} setOpen={setOpen} id={menuId} />
      </FocusLock>
    </div>
  )
}
