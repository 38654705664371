import React from 'react'
import { AboutUs } from '../../components/AboutUs'
import { Contact } from '../../components/Contact'
import { DownloadPortfolio } from '../../components/DowloadPortFolio'
import { Brands } from '../../components/Brands'
import { Header } from '../../components/Header'
import Goo from '../../components/Header/backGround'
import { Portfolio } from '../../components/Portfolio'
import { Services } from '../../components/Services.js'

import {Link }from 'react-scroll'
import { useState } from 'react'

export const Home = () => {
  const sections = ['home','about','works','services','brands','download']
  const [currentCount, setCurrentCount] = useState(1)
  const [current, setCurrent] = useState(sections[currentCount])
  return (
    <>
    <Goo />
    <Header />
    <hr/>
    <AboutUs />
    <hr/>
    <Portfolio />
    <hr/>
    <Services />
    <hr/>
    <Brands />
    <hr/>
    <DownloadPortfolio />
    <hr/>
    <Contact />
    <a href="https://wa.me/51962041865?text=Hola,%20me%20interesa%20uno%20de%20tus%20servicios!%20" className="float" target="_blank" rel="noopener noreferrer">
      <i className="fa fa-whatsapp my-float"></i>
    </a>
    {
      currentCount < 7 &&
      <Link  activeClass="active" to={current} spy={true} smooth={true} duration={500} href="/" className="float-left" onClick={()=>{
        setCurrentCount(currentCount + 1)
        console.log(currentCount);
        setCurrent(sections[currentCount])
        }}>
          <span aria-hidden="true"><i className="fas fa-arrow-down my-float-left"></i></span>
      </Link >
    }
    </>
  )
}
