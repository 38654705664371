import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import {Link }from 'react-scroll'

const Menu = ({ open, ...props }) => {
  
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <Link  activeClass="active" to="home" spy={true} smooth={true} duration={500} href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">🏁</span>
        Empecemos
      </Link >
      <Link  activeClass="active" to="about" spy={true} smooth={true} duration={500} href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">💁🏻‍♂️</span>
        Nosotros
      </Link >
      <Link  activeClass="active" to="works" spy={true} smooth={true} duration={500} href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">💼</span>
        Nuestro Trabajo
      </Link >
      <Link  activeClass="active" to="services" spy={true} smooth={true} duration={500} href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">💸</span>
        Servicios
      </Link >
      <Link  activeClass="active" to="brands" spy={true} smooth={true} duration={500} href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">🏢</span>
        Confianza
      </Link >
      <Link  activeClass="active" to="download" spy={true} smooth={true} duration={500} href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">📩</span>
        Quiero saber más!
        </Link >
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
