import styled from 'styled-components'

export const ServiceContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 100%;
    }
`