/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    css:
      'url(https://live.staticflickr.com/65535/51010031331_a93b69b32c_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010132152_65c5302a62_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010132112_f465934d83_k.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010031276_137ffd8248_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010031276_137ffd8248_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51009323148_f5f914dcae_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010132662_582d76d55f_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51009322758_2f716229d3_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010030516_97ce31f083_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51009411773_51a521019c_b.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51009406088_4114aaa9f2_h.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010111036_1cb9da64f7_c.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010158052_a0294006a3_c.jpg)',
    height: 450
  },
  {
    css:
      'url(https://live.staticflickr.com/65535/51010110241_759688c004_b.jpg)',
    height: 450
  },
  
]
