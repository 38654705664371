import React from 'react';
import { Container } from 'react-bootstrap';
import { BrandsContainer } from './styles';


export const Brands = () => {
  const brands = ["https://live.staticflickr.com/65535/51106279343_3787682736_h.jpg",
  "https://live.staticflickr.com/65535/51106279333_968a4d7981_c.jpg",
  "https://live.staticflickr.com/65535/51106279328_b2f7646a4e_h.jpg",
  "https://live.staticflickr.com/65535/51105807057_ec91106221_c.jpg",
  "https://live.staticflickr.com/65535/51106121944_92d7345925_n.jpg",
  "https://live.staticflickr.com/65535/51108083231_2208d739af_c.jpg",
  "https://live.staticflickr.com/65535/51107508507_e3069377dd_b.jpg",
  "https://live.staticflickr.com/65535/51107999478_d2eaa8ef63_b.jpg",
  "https://live.staticflickr.com/65535/51114640891_657609c443_b.jpg",
  "https://live.staticflickr.com/65535/51114640431_ce69321617_n.jpg",
  "https://live.staticflickr.com/65535/51114392629_402e10ddf3_b.jpg",
  "https://live.staticflickr.com/65535/51114391574_f77d9907c2_b.jpg",
  "https://live.staticflickr.com/65535/51114139873_1f8a8cd6b2_n.jpg",
  "https://live.staticflickr.com/65535/51114231276_1dc6190037_z.jpg",
  "https://live.staticflickr.com/65535/51114139858_64528d5f89_n.jpg",
  "https://live.staticflickr.com/65535/51114139848_9d0d2e179d_z.jpg",
  "https://live.staticflickr.com/65535/51114139818_a62e037bbd_b.jpg"]
  return (
    <BrandsContainer id='brands'>
    <Container className='d-flex flex-column justify-content-center align-items-center mt-5 mb-5 pt-5 pb-5'>
      <h1>Ellos confian en nosotros</h1>
      <div className='text-center'>
        {
          brands.map((i, key) => (
            <div key={key} className='m-3 d-inline'>
              <img src={i} alt="altImg"  width='200px' className='m-3'/>
            </div>
          ))
        }        
      </div>
    </Container>
    </BrandsContainer>
  );
};

