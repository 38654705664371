import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import { NavigationBar } from './containers/NavigationBar';
import { Home } from './containers/Home';
function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <NavigationBar />
        <Home />
        
      </>
    </ThemeProvider>
  );
}

export default App;
