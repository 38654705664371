import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { PersonContainer, Names, SpamItem, AboutCotainer } from './styles'
export const AboutUs = () => {

  const members = [
    {
      "name": "Anthony Peña",
      "rol": "CEO / Full Stack Developer"
    },
    {
      "name": "Annabel Velarde",
      "rol": "CMO / Marketing & Design Leader"
    },
    {
      "name": "Luciano Velazco",
      "rol": "CFO / Mobile developer"
    },
  ]
  return (
    <AboutCotainer id='about'>
      <Container>
        <Row>
          <Col md={6}>
            <h1 className='mt-5 mb-5'>Nosotros</h1>
            <p className='text-justify' style={{ fontSize: 24 }}>Poopaye Consulting es una agencia consultora creada por un gran equipo cuyo objetivo es a apoyar, crecer y desarrollar nuevos proyectos y emprendimientos con la finalidad que alcancen sus objetivos, surgiendo en base a calidad y eficiencia de nuestro equipo.</p>
          </Col>
          <Col md={6} className='d-flex justify-content-center flex-column p-5'>
            <h2 className='mb-4'>Nuestro Equipo Fundador</h2>
            {
              members.map((i, key) => (
                <PersonContainer key={key}>
                  <SpamItem>{key + 1}</SpamItem>
                  <div className='ml-4'>
                    <Names>{i.name}</Names>
                    <h5>{i.rol}</h5>
                  </div>
                </PersonContainer>
              ))
            }
          </Col>

        </Row>
      </Container>

    </AboutCotainer>
  )
}
