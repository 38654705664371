import React from 'react';
import { HeaderContainer } from './styles';
import Logo from '../../assets/static/logoWhite.png'

export const Header = () => {
  return (
    <div id='home'>
    <HeaderContainer>
      {/* <Robot1/> */}
      <img src={Logo} alt="Logo" width='600px'/>
      {/* <img src="https://image.flaticon.com/icons/svg/2016/2016012.svg" alt="burger icon" styles="height: 90px;"/> */}
      <h1>Software, Marketing y Diseño</h1>
      
    </HeaderContainer>

    </div>
  )
}
