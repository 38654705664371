import styled from 'styled-components'

export const AboutCotainer = styled.h2`
  margin-bottom: 10px;
  height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 100%;
    }
`

export const Names = styled.h2`
  color: #5000ff;
`

export const PersonContainer = styled.div`
  display: flex;
  align-items: center;
  
`

export const SpamItem = styled.p`
  padding:10px 16px;
  border-radius:50px;
  background-color: ${({ theme  }) => theme.primaryHover};
  display:inline;
  color: white;
`